import axios from 'axios'
import store from './store/store.js'
import router from './router'

let isAlreadyFetchingAccessToken = false

export const http = axios.create({
    baseURL:
        process.env.NODE_ENV === 'production'
            ? 'https://www.mylastwill.co.uk/broker/api/v2/'
            : 'http://127.0.0.1:8000/broker/api/v2/',
    xhrFields: {
        withCredentials: true,
    },
    headers: {
        'Content-Type': 'application/json',
    },
})

const errorInterceptor = (error) => {
    switch (error.response.status) {
        case 401: // authentication error, logout the user
            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true

                return store
                    .dispatch('inspectToken')
                    .then((_) => {
                        isAlreadyFetchingAccessToken = false
                        error.config.headers[
                            'Authorization'
                            ] = `JWT ${store.getters.tokens.access}`
                        return axios.request(error.config)
                    })
                    .catch((error) => {
                        store.dispatch('logout')
                        isAlreadyFetchingAccessToken = false
                        return error
                    })
            }

            store.commit(
                'loginError',
                'Authentication error ' + error.response.data.detail,
            )
            break
        case 403: // Incorrect permission
            console.log('catch 403')
            router.push('/login').catch((err) => {
            })
            store.commit(
                'loginError',
                'Incorrect permissions (User must be staff) ' +
                error.response.data.detail,
            )
            break
        default:
            console.error('server error')
    }
    return Promise.reject(error)
}

const responseInterceptor = (response) => {
    switch (response.status) {
        case 200:
            break
        default:
            break
    }
    return response
}

http.interceptors.response.use(responseInterceptor, errorInterceptor)
